<template>
  <div class="mainPage">
    <div class="loginForm">
      <img src="../assets/LogoKonfrensi.png" alt />
      <form @submit.prevent="login()">
        <input type="text" class="form-control" v-model="username" placeholder="username" />
        <input type="password" class="form-control" v-model="password" placeholder="password" />
        <button type="submit" class="btn btn-success mt-2">Login</button>
      </form>
      <p>copyright © 2021 - otnielpangkung</p>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "../API/axios";
export default {
  name: "LoginPage",
  data() {
    return {
      username: "",
      password: ""
    };
  },
  methods: {
    login() {
      return axios
        .post("/user/login", {
          username: this.username,
          password: this.password
        })
        .then(({ data }) => {
          console.log(data);
          Swal.fire("Syalom", "Selamat Datang di Voter Konferensi", "success");
          localStorage.setItem("access_token", data.access_token);
          localStorage.setItem("role", data.role);
          this.$router.push("/");
          this.$store.commit("setUserLogin", true);
        })
        .catch(err => {
          console.log(err);

          Swal.fire("Maaf", "Password / Username Salah!", "error");
        });
    }
  }
};
</script>

<style scoped>
.mainPage {
  display: flex;
  flex-direction: column;

  min-height: 85vh;
  justify-content: center;
  /* align-items: center; */
}
img {
  max-width: 200px;
  align-self: center;
}
.loginForm {
  display: grid;
  justify-content: center;
}
.input-group {
  display: grid;
}
input {
  max-width: 200px;
  border: 1px rgb(150, 150, 150) solid;
  border-radius: 10px;
  margin-top: 10px;
  height: 35px;
  text-align: center;
  font-size: 20px;
}

p {
  font-size: 12px;
  margin-top: 5px;
}
button {
  width: 100%;
}
</style>