import { createStore } from 'vuex'
import axios from "../API/axios"

export default createStore({
  state: {
    ketuas: [],
    balonKetua: [],
    bendahara: [],
    balonbendahara: [],
    sekretaris: [],
    balonsekretaris: [],
    users: []
  },
  mutations: {
    setKetuas(state, data) {
      state.ketuas = data
    },
    setBalonKetua(state, data) {
      state.balonKetua = data
    },
    setBendahara(state, data) {
      state.bendahara = data
    },
    setBalonBendahara(state, data) {
      state.balonbendahara = data
    },
    setSekretaris(state, data) {
      state.sekretaris = data
    },
    setBalonSekretaris(state, data) {
      state.balonsekretaris = data
    },
    setUser(state, data) {
      state.users = data
    }

  },
  actions: {
    getKetua(context) {
      return axios.get("/vote/ketua", {
        headers: {
          access_token: localStorage.getItem("access_token")
        }
      }).then(({ data }) => {
        context.commit("setKetuas", data)
      }).catch(err => {
        console.log(err);
      })
    },
    getBalonKetua(context) {
      return axios.get("/vote/balonketua", {
        headers: {
          access_token: localStorage.getItem("access_token")
        }
      }).then(({ data }) => {
        context.commit("setBalonKetua", data)
      }).catch(err => {
        console.log(err);
      })
    },
    getBendahara(context) {
      return axios.get("/vote/Bendahara", {
        headers: {
          access_token: localStorage.getItem("access_token")
        }
      }).then(({ data }) => {
        context.commit("setBendahara", data)
      }).catch(err => {
        console.log(err);
      })
    },
    getBalonBendahara(context) {
      return axios.get("/vote/BalonBendahara", {
        headers: {
          access_token: localStorage.getItem("access_token")
        }
      }).then(({ data }) => {
        context.commit("setBalonBendahara", data)
      }).catch(err => {
        console.log(err);
      })
    },
    getSekretaris(context) {
      return axios.get("/vote/sekretaris", {
        headers: {
          access_token: localStorage.getItem("access_token")
        }
      }).then(({ data }) => {
        context.commit("setSekretaris", data)
      }).catch(err => {
        console.log(err);
      })
    },
    getBalonSek(context) {
      return axios.get("/vote/balonsek", {
        headers: {
          access_token: localStorage.getItem("access_token")
        }
      }).then(({ data }) => {
        context.commit("setBalonSekretaris", data)
      }).catch(err => {
        console.log(err);
      })
    },
    setUser(context) {
      return axios.get("/user", {
        headers: {
          access_token: localStorage.getItem("access_token")
        }
      }).then(({ data }) => {
        context.commit("setUser", data)
      }).catch(err => {
        console.log(err);
      })
    },
  },
  modules: {
  }
})
