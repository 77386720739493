<template>
  <div class="Bendahara">
    <div class="formBox">
      <form @submit.prevent="confirmPost()">
        <div class="col-sm">
          <img src="../assets/LogoKonfrensi.png" alt />
          <p>Pemilihan Bendahara</p>
          <!-- <label class="my-1 mr-2" for="Username">Bakal Calon Bendahara</label> -->
          <div class="input-group">
            <select
              class="form-control"
              id="RelasiId"
              v-model="BakalCalonId"
              aria-placeholder="Relasi"
              required
            >
              <option selected disabled>Pilih Bakal Calon Bendahara :</option>
              <option v-for="balon in this.hasil" :key="balon.id" :value="balon.id">{{balon.nama}}</option>
            </select>
          </div>
          <!-- {{this.hasil}} -->
        </div>
        <button type="submit" class="btn btn-primary mt-2">Pilih</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "../API/axios";
import Swal from "sweetalert2";
export default {
  name: "Bendahara",
  data() {
    return {
      BakalCalonId: 0,
      hasil: []
    };
  },
  methods: {
    getBendahara() {
      return axios
        .get("/vote/bendahara", {
          headers: {
            access_token: localStorage.getItem("access_token")
          }
        })
        .then(data => {
          //   console.log(data);
          this.hasil = data.data;
        });
    },
    confirmPost() {
      Swal.fire({
        title: "Yakin?",
        text: "Sudah Yakin Dengan Pilihannya?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yakin",
        cancelButtonText: "Batal"
      }).then(result => {
        if (result.isConfirmed) {
          this.vote();
          // Swal.fire("Berhasil!", "Data Anda Berhasil Ditambahkan", "success");
          this.$router.push("/about");
        } else {
          Swal.fire("Ok", "Permintaan Anda telah Dibatalkan", "error");
        }
      });
    },
    vote() {
      return axios
        .post(
          `/vote/Bendahara/${this.BakalCalonId}`,
          {},
          {
            headers: {
              access_token: localStorage.getItem("access_token")
            }
          }
        )
        .then(result => {
          Swal.fire("Berhasil!", "Data Anda Berhasil Ditambahkan", "success");
        })
        .catch(err => {
          Swal.fire("Maaf", "Anda Telah Melakukan Pilihan", "error");
        });
    }
  },
  created() {
    this.getBendahara();
  }
};
</script>

<style scoped>
img {
  max-width: 150px;
}
p {
  font-size: 20px;
}
select {
  justify-items: center;
  text-align: center;
  font-weight: bold;
  /* max-width: 100px; */
}
option {
  font-weight: bold;
}
.Bendahara {
  display: grid;
  min-height: 80vh;
  align-content: center;
  justify-content: center;
}
@media screen and (min-width: 800px) {
  * {
    display: none;
  }
}
</style>