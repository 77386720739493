import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from "../views/Login.vue"

import BalonKetua from "../views/BalonKetua.vue"
import BalonBendahara from "../views/BalonBendahara.vue"
import BalonSekretaris from "../views/BalonSekretaris.vue"
import Ketua from "../views/Ketua.vue"
import Bendahara from "../views/Bendahara.vue"
import Sekretaris from "../views/Sekretaris.vue"

import HasilBalonKetua from "../views/HasilBalonKetua.vue"
import HasilKetua from "../views/HasilKetua.vue"
import HasilBalonBendahara from "../views/HasilBalonBendahara.vue"
import HasilBendahara from "../views/HasilBendahara.vue"
import HasilBalonSek from "../views/HasilBalonSek.vue"
import HasilSekretaris from "../views/HasilSekretaris.vue"

import Management from "../views/Management.vue"

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/balonketua',
    name: 'BalonKetua',
    component: BalonKetua,
    meta: { requiresAuth: true }
  },
  {
    path: '/balonbendahara',
    name: 'BalonBendahara',
    component: BalonBendahara,
    meta: { requiresAuth: true }
  },
  {
    path: '/balonsekretaris',
    name: 'BalonSekretaris',
    component: BalonSekretaris,
    meta: { requiresAuth: true }
  },
  {
    path: '/ketua',
    name: 'Ketua',
    component: Ketua,
    meta: { requiresAuth: true }
  },
  {
    path: '/sekretaris',
    name: 'Sekretaris',
    component: Sekretaris,
    meta: { requiresAuth: true }
  },
  {
    path: '/bendahara',
    name: 'Bendahara',
    component: Bendahara,
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/hasilbalonketua',
  //   name: 'HasilBalonKetua',
  //   component: HasilBalonKetua,
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/hasilketua',
  //   name: 'HasilKetua',
  //   component: HasilKetua,
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/hasilbalonbendahara',
  //   name: 'HasilBalonBendahara',
  //   component: HasilBalonBendahara,
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/hasilbendahara',
  //   name: 'HasilBendahara',
  //   component: HasilBendahara,
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/hasilbalonsek',
  //   name: 'HasilBalonSek',
  //   component: HasilBalonSek,
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/hasilsekretaris',
  //   name: 'HasilSekretaris',
  //   component: HasilSekretaris,
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/management',
  //   name: 'Management',
  //   component: Management,
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => { // pengecekan meta required
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('access_token')) {
      alert("Anda Harus Login terlebih dahulu ya..!!")
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})
export default router
