<template>
  <div class="home">
    <h2>Anda Telah Berhasil Login</h2>
    <button class="btn btn-danger" @click.prevent="logout()">LOGOUT</button>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import axios from "../API/axios";
export default {
  name: "Home",
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    }
  }
};
</script>
